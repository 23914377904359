import { AdsInfiniteListPage, type InfinityAds } from '@/modules/ads';
import { pageFactory } from '@/modules/app/components';
import { withCatalogCheck, withCityCheck } from '@/hoc';

const { Wrapper: CatalogPageWithCityCheck, getServerSideProps } = withCityCheck(
  AdsInfiniteListPage,
  withCatalogCheck,
);

export { getServerSideProps };
export default pageFactory<{ pages: InfinityAds[], host: string }>(CatalogPageWithCityCheck);
