import { categoriesList } from '@/modules/seo';
import { getCatalogServerSideProps } from '@/modules/shared/helpers/get-catalog-server-side-props.helper';
import { GetServerSideProps } from 'next';

const validatePath = (pathArray: string[] | [], obj: typeof categoriesList) => {
  if (!pathArray.length) return true;
  const [current, ...rest] = pathArray;

  if (!obj[current] || typeof obj[current] !== 'object') {
    return false;
  }

  return validatePath(rest, obj[current]);
};

export const withCatalogCheck: GetServerSideProps = async (context) => {
  const { params, resolvedUrl } = context;

  const { category, subcategory, undersubcategory } = params as {
    category?: string;
    subcategory?: string;
    undersubcategory?: string;
  };

  const pathArray: string[] = [];
  if (category) pathArray.push(category);
  if (subcategory) pathArray.push(subcategory);
  if (undersubcategory) pathArray.push(undersubcategory);

  const isValid = validatePath(pathArray, categoriesList);

  if (pathArray.length === 0 && !resolvedUrl.includes('/catalog/all')) {
    return { notFound: true };
  }

  if (!isValid) {
    return { notFound: true };
  }

  return getCatalogServerSideProps(context);
};
