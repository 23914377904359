
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/[city]/catalog/all",
      function () {
        return require("private-next-pages/[city]/catalog/all/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/[city]/catalog/all"])
      });
    }
  