import { GetServerSideProps, GetServerSidePropsContext, GetServerSidePropsResult } from 'next';
import { createRequestContext } from '@/modules/shared/api';

/**
 * api из shared/api не подхватывает токен из cookies и с сервера не получится сделать запрос (если он требует авторизации).
 * Поэтому нужно добавить его в заголовки вручную.
 * Если токена в cookies нет - перенаправляем на главную страницу с открытой авторизацией,
 * а после авторизации - обратно на страницу, куда пользователь пытался попасть.
 * Можно или передавать аргументом getServerSideProps функцию (будет вызвана после проверки токена),
 * или просто использовать без аргументов.
 * @example
 * ```tsx
 * export default function SomeComponent() {
 *  .....
 * }
 *
 * export const getServerSideProps = withAccessToken();
 *
 * ```
 * @example
 * ```tsx
 * export const getServerSideProps = withAccessToken(async () => ({
 *   .....
 *   props: { .... },
 * }));
 *
 * export default SomeComponent;
 * ```
 * */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withAccessToken = <P extends Record<string, unknown>>(getServerSidePropsFunc?: GetServerSideProps<P>) => async (context: GetServerSidePropsContext): Promise<GetServerSidePropsResult<P>> => {
  const { resolvedUrl } = context;
  const requestCtx = createRequestContext(context);

  if (!requestCtx.accessToken) {
    return {
      redirect: {
        destination: `/?auth-step=login&redirectAfterLogin=${encodeURIComponent(resolvedUrl)}`,
        statusCode: 302,
      },
    };
  }

  if (getServerSidePropsFunc) {
    return getServerSidePropsFunc(context);
  }

  return {
    props: {} as P,
  };
};

export { withAccessToken };
