import { ComponentType } from 'react';
import { GetServerSideProps, GetServerSidePropsContext } from 'next';
import { checkExistedCityAndRedirect } from '@/modules/shared/helpers';

// hoc для проверки города в адресной строке, чтобы в выбор города не подставлялось все подряд из адресной строки
export const withCityCheck = (
  PageComponent: ComponentType,
  pageGetServerSideProps?: GetServerSideProps,
) => {
  const Wrapper = (props) => <PageComponent {...props} />;

  const getServerSideProps: GetServerSideProps = async (context: GetServerSidePropsContext) => {
    const { resolvedUrl } = context;
    const cookieString = context.req.headers.cookie || '';

    const redirect = checkExistedCityAndRedirect(resolvedUrl, cookieString);
    if (redirect) {
      return redirect;
    }

    if (pageGetServerSideProps) {
      return pageGetServerSideProps(context);
    }

    return {
      props: {},
    };
  };

  return { Wrapper, getServerSideProps };
};

/**
 * пример использования на странице с дополнительным getServerSideProps, например для запроса со стороны сервера
 * const pageGetServerSideProps = async () => {
 *   const data = await fetchSomething();
 *
 *   return {
 *     props: { data },
 *   };
 * };
 *
 * const { Wrapper: PostersPageWithCityCheck, getServerSideProps } = withCityCheck(PostersPage, pageGetServerSideProps);
 *
 * export { getServerSideProps };
 * export default PostersPageWithCityCheck;
 * */
