import { GetServerSideProps } from 'next';
import { last, omit } from 'lodash';
import { AdsApi } from '@/modules/ads/api';
import { modalTypeParam } from '@/modules/auth/consts';
import { getServerHost } from './get-server-host';

type FilterValue = string | number | boolean | null;

export const getCatalogServerSideProps: GetServerSideProps = async (context) => {
  const { params, query, req } = context;
  const host = getServerHost(req);

  try {
    const citiesFromQuery = last(req.url?.split('?'))
      ?.split('&')
      ?.filter((el) => el.includes('city='))
      .map((el) => el.replace('city=', ''));
    const omittedQuery = omit(query, ['city', 'category', 'subcategory', 'undersubcategory', 'order', modalTypeParam]);

    const filters: Record<string, string | string[]> = {
      ...omittedQuery as Record<string, FilterValue | Array<FilterValue>>,
      category_codename: params?.category || '',
      sub_category_codename: params?.subcategory || '',
      under_sub_category_codename: params?.undersubcategory || '',
    };

    if (citiesFromQuery && citiesFromQuery.length) {
      filters.city = citiesFromQuery.filter((el) => Number(el));
    }
    if (params?.city && !Number(params.city) && params.city !== 'allcities' && !filters.city) {
      filters.city_codename = params.city;
    }

    const data = await AdsApi.list({
      filters,
      order: query.order as string ?? '-created_at',
    });

    return {
      props: {
        pages: data,
        host,
      },
    };
  } catch (error) {
    if (error?.response?.status === 404) {
      return {
        notFound: true,
      };
    }
    return {
      props: {
        serverError: true,
      },
    };
  }
};
