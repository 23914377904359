import {
  GetServerSideProps,
  GetServerSidePropsContext,
  GetServerSidePropsResult,
} from 'next';
import { getServerSideCookieValue } from '@/modules/shared/helpers';
import { MeApi } from '@/modules/me/api';
import { triggerEvent } from '@/modules/rollbar';

type Role = 'admin' | 'moderator';

enum RoleEnum {
  ADMIN = 'admin',
  MODERATOR = 'moderator',
}

/**
 * HOF для защиты страниц на основе ролей пользователя.
 *
 * @param role - Роль, необходимая для доступа к странице ('admin' или 'moderator').
 * @param getServerSidePropsFunc - (Необязательно) Функция `getServerSideProps`, которая будет вызвана после проверки роли.
 *
 * @returns Функция `getServerSideProps`, которая проверяет наличие токена и роль пользователя.
 *
 * @example
 * ```tsx
 * export default function AdminPage() {
 *   return <div>Admin Content</div>;
 * }
 *
 * export const getServerSideProps = withRoleProtection('admin');
 * ```
 * @example
 * ```tsx
 * export default function AdminPage({ data }) {
 *   return <div>Admin Content: {data}</div>;
 * }
 *
 * export const getServerSideProps = withRoleProtection('admin', async (context) => {
 *   .......
 * });
 * ```
 */

export const withRoleProtection = <P extends Record<string, unknown>>(
  role: Role,
  getServerSidePropsFunc?: GetServerSideProps<P>,
) => async (
    context: GetServerSidePropsContext,
  ): Promise<GetServerSidePropsResult<P>> => {
    const { req, resolvedUrl } = context;
    const cookieString = req.headers.cookie || '';
    const token = getServerSideCookieValue(cookieString, 'access_token');

    if (!token) {
      return {
        redirect: {
          destination: `/?auth-step=login&redirectAfterLogin=${encodeURIComponent(resolvedUrl)}`,
          permanent: false,
        },
      };
    }

    try {
      const me = await MeApi.getMe({
        Authorization: `Bearer ${token}`,
      });

      if (role === RoleEnum.MODERATOR && (!me.is_moderator && !me?.is_admin)) {
        triggerEvent(new Error(`User with ID: ${me.id} does not have moderator role`));

        return {
          notFound: true,
        };
      }

      if (role === RoleEnum.ADMIN && !me?.is_admin) {
        triggerEvent(new Error(`User with ID: ${me.id} does not have admin role`));

        return {
          notFound: true,
        };
      }
    } catch (error) {
      triggerEvent(new Error('Error fetching user data on server side'));
      console.error('Error fetching user data on server side: ', error);
      return {
        notFound: true,
      };
    }

    if (getServerSidePropsFunc) {
      return getServerSidePropsFunc(context);
    }

    return {
      props: {} as P,
    };
  };
